export interface SessionStorageData {
  onboarding: Partial<{
    currentStep: number
    info: {
      name?: string
      age?: string
      goal?: string
      sex?: string
      improves: string[]
      duration?: string
      profession?: string
      interests: string[]
      languageLevel?: string
    }
    started: boolean
    userLastStep: number
    isForward: boolean
    completed: boolean
  }>
}

export type SessionStorageKeys = keyof SessionStorageData

export class AppSessionStorage {
  constructor(private storage: Storage) {}

  get<T extends SessionStorageKeys>(key: T): SessionStorageData[T] | null {
    const data = this.storage.getItem(key)

    if (data !== null) {
      return JSON.parse(data) as SessionStorageData[T]
    }

    return null
  }

  set<T extends SessionStorageKeys>(
    key: T,
    value: SessionStorageData[T] | undefined,
  ) {
    if (!value) {
      this.remove(key)
    } else {
      this.storage.setItem(key, JSON.stringify(value))
    }
  }

  remove(key: SessionStorageKeys) {
    this.storage.removeItem(key)
  }
}
