/* eslint-disable valtio/state-snapshot-rule */
import {
  ProgramContext,
  useProgramStore,
} from '../features/programs/programView.tsx'
import { useIsMobile, useLazyRef } from '../shared/lib/hooks.ts'
import { useAppModule } from '../features/appContext.ts'
import { cn, sortBy, useMount } from '../shared/lib/utils.ts'
import {
  AppHeader,
  AppHeaderNavigationButton,
} from '../features/appHeader/appHeader.tsx'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSnapshot } from 'valtio/react'
import {
  Drawer,
  DrawerBar,
  DrawerClose,
  DrawerContent,
  DrawerTrigger,
} from '../shared/ui/drawer/drawer.tsx'
import { Icon } from '../shared/ui/icon/icon.tsx'
import { ProgramItem } from '../shared/api/chatApi.ts'
import { LinkButton } from '../shared/ui/button/button.tsx'
import { urls } from '../shared/urls.ts'

export function ProgramPage2(props: { registration: boolean }) {
  const appModule = useAppModule()

  const store = useLazyRef(() =>
    appModule.programStore(props.registration),
  ).current

  useMount(() => {
    void store.loadPrograms()
  })

  return (
    <ProgramContext.Provider value={store}>
      <div className="flex w-full flex-col overflow-hidden">
        <ProgramPageHeader />

        <div className="pt-16 msm:pt-100">
          <TopicsList />
        </div>

        <Units />
      </div>
    </ProgramContext.Provider>
  )
}

function ProgramPageHeader() {
  const isMobile = useIsMobile()

  if (isMobile) {
    return <ProgramPageHeaderMobile />
  }

  return <AppHeader />
}

function ProgramPageHeaderMobile() {
  return (
    <div
      className={cn(
        'flex h-100 w-full items-center px-16',
        'fixed left-0 top-0 z-10 bg-white',
      )}
    >
      <div className="flex w-full items-center justify-between pr-24">
        <AppHeaderNavigationButton />

        <div className="mx-auto">
          <LevelBottomSheet />
        </div>
      </div>
    </div>
  )
}

export function LevelBottomSheet() {
  const store = useProgramStore()
  const state = useSnapshot(store.state)

  const [isOpen, setIsOpen] = React.useState(false)

  return (
    <Drawer
      open={isOpen}
      onClose={() => {
        setIsOpen(false)
      }}
    >
      <DrawerTrigger
        asChild
        onClick={() => {
          setIsOpen((prev) => !prev)
        }}
      >
        <button className="flex cursor-pointer items-center gap-8 p-0 text-18 font-black text-black outline-0">
          <FormattedMessage
            id="personalCourse"
            values={{ level: state.selectedLevel }}
          />{' '}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={10}
            height={5}
            fill="none"
          >
            <path
              fill="#000"
              d="M3.937 4.732.174.969a.61.61 0 0 1-.13-.186.573.573 0 0 1 .115-.61.533.533 0 0 1 .413-.173h7.923c.17 0 .307.058.413.174a.573.573 0 0 1 .159.398c0 .041-.059.174-.175.398L5.13 4.732a.821.821 0 0 1-.283.193.867.867 0 0 1-.314.056.867.867 0 0 1-.314-.056.821.821 0 0 1-.283-.193Z"
            />
          </svg>
        </button>
      </DrawerTrigger>
      <DrawerContent
        direction="bottom"
        className="mt-24 flex h-auto flex-col rounded-t bg-white"
      >
        <DrawerBar />
        <div className="mt-24 flex h-[90dvh] flex-col">
          <div className="flex justify-between px-16">
            <div className="ml-12 text-20 font-bold">
              <FormattedMessage id="chooseLevel" />
            </div>
            <DrawerClose
              asChild
              onClick={() => {
                setIsOpen(false)
              }}
            >
              <div className="flex size-32 shrink-0 items-center justify-center rounded-full bg-gray">
                <Icon iconName="close" className="text-black" />
              </div>
            </DrawerClose>
          </div>
          <div className="flex-1 overflow-y-auto [scrollbar-width:thin]">
            <LevelsList
              onChange={() => {
                setIsOpen(false)
              }}
            />
          </div>
        </div>
      </DrawerContent>
    </Drawer>
  )
}

interface LevelsListProps {
  onChange: () => void
}

export function LevelsList({ onChange }: LevelsListProps) {
  const store = useProgramStore()
  const state = useSnapshot(store.state)

  const levelsMap = {
    A1: 'A1 — Beginner',
    A2: 'A2 — Elementary',
    B1: 'B1 — Intermediate',
    B2: 'B2 — Upper Intermediate',
    C1: 'C1 — Advanced',
    C2: 'C2 — Proficiency',
  }
  const levels = state.programTree.map(
    (x) => x.level,
  ) as (keyof typeof levelsMap)[]

  return (
    <div className="mt-28 flex flex-col gap-8 px-16">
      {levels.map((level) => (
        <div
          key={level}
          className="flex cursor-pointer items-center gap-8 rounded-12 bg-gray-light px-12 py-16"
          onClick={() => {
            store.setSelectedLevel(level)
            onChange()
          }}
        >
          <div className="flex size-20 items-center justify-center rounded-full border-2 border-main bg-white">
            {state.selectedLevel === level && (
              <div className="size-12 rounded-full bg-main"></div>
            )}
          </div>

          <div className="flex items-center justify-between text-16 font-bold">
            {levelsMap[level]}
          </div>
        </div>
      ))}
    </div>
  )
}

interface TopicCard {
  title: string
  index: number
  lessonsAmount: number
  isActive?: boolean
  onClick: () => void
}

function TopicCard({
  title,
  index,
  lessonsAmount,
  isActive,
  onClick,
}: TopicCard) {
  return (
    <div
      className={cn(
        'h-200 min-w-160 max-w-160 shrink-0 grow-0 basis-auto cursor-pointer overflow-hidden rounded-18 bg-gray-light p-12',
        isActive && 'bg-active-topic-card shadow-active-topic-card',
      )}
      onClick={onClick}
    >
      <img src={`/images/program/topics/topic-${index}.png`} />

      <div
        className={cn(
          'mt-10 text-12 font-extrabold uppercase',
          isActive ? 'text-topic-card-header-active' : 'text-topic-card-header',
        )}
      >
        <FormattedMessage id="Topic" /> {index}
      </div>

      <div
        className={cn(
          'mt-4 line-clamp-2 overflow-hidden text-ellipsis break-words text-20 font-extrabold capitalize leading-6',
          isActive ? 'text-white' : 'text-black',
        )}
      >
        {title}
      </div>

      <div
        className={cn(
          'mt-8 font-medium opacity-50',
          isActive ? 'text-white' : 'text-black',
        )}
      >
        {lessonsAmount}{' '}
        <FormattedMessage
          id="lessonsPlural"
          values={{ count: lessonsAmount }}
        />
      </div>
    </div>
  )
}

function TopicsList() {
  const store = useProgramStore()
  const state = useSnapshot(store.state)

  // eslint-disable-next-line valtio/state-snapshot-rule
  const topics =
    state.programTree.find((x) => x.level === state.selectedLevel)?.items ?? []

  return (
    <div className={cn('flex gap-16 overflow-auto px-16 pb-16')}>
      {topics.map((x, i) => {
        const lessonsAmount = x.items
          .map((x) => x.items.length)
          .reduce((acc, amount) => acc + amount, 0)

        return (
          <TopicCard
            key={x.program}
            index={i + 1}
            lessonsAmount={lessonsAmount}
            isActive={state.selectedProgram === x.program}
            title={x.program}
            onClick={() => {
              store.setSelectedProgram(x.program)
            }}
          />
        )
      })}
    </div>
  )
}

interface UnitHeaderProps {
  title: string
  index: number
}

function UnitHeader({ title, index }: UnitHeaderProps) {
  return (
    <div className="px-16">
      <div className="text-24 font-extrabold capitalize text-black">
        <FormattedMessage id="unit" /> {index}
      </div>

      <div className="overflow-hidden text-ellipsis text-nowrap text-18 font-extrabold capitalize leading-none text-black">
        {title}
      </div>
    </div>
  )
}

function Units() {
  const store = useProgramStore()
  const selectedCycles = useSnapshot(store.state).selectedCycles

  return (
    <div className="mx-auto my-0 w-full p-16">
      {selectedCycles.map((cycle) => {
        return (
          <Unit
            key={cycle.index}
            index={cycle.index + 1}
            title={cycle.title}
            lessons={cycle.items}
          />
        )
      })}
    </div>
  )
}

interface UnitProps {
  title: string
  index: number
  lessons: readonly ProgramItem[]
}

function Unit({ title, index, lessons }: UnitProps) {
  const store = useProgramStore()
  const state = useSnapshot(store.state)

  return (
    <div className="mt-24">
      <UnitHeader index={index} title={title} />

      <div className="mt-12 flex flex-wrap gap-16 msm:flex-col">
        {sortBy(lessons, (x) => x.order).map((lesson, i) => {
          return (
            <Lesson
              key={lesson.id}
              id={lesson.id}
              tag={lesson.tag}
              isSelected={lesson.id === state.selectedLessonId}
              isCompleted={lesson.status === 'completed'}
              inProgress={lesson.status === 'active'}
              index={i + 1}
              title={lesson.lesson_subject}
              type_name={lesson.lesson_name}
              type={lesson.lesson_type}
            />
          )
        })}
      </div>
    </div>
  )
}

interface LessonProps {
  title: string
  type_name: string
  tag: string
  id: number
  index: number
  type: string
  isSelected?: boolean
  isCompleted?: boolean
  inProgress?: boolean
}

function Lesson(props: LessonProps) {
  const store = useProgramStore()

  const lessonTypeIcons: Record<string, string> = {
    'Warm Up': '/images/program/lessons/human.png',
    Grammar1: '/images/program/lessons/grammar.png',
    Reading: '/images/program/lessons/reading.png',
    Grammar2: '/images/program/lessons/grammar.png',
    Listening: '/images/program/lessons/listening.png',
    Vocabulary1: '/images/program/lessons/vocab.png',
    Vocabulary2: '/images/program/lessons/vocab.png',
  }

  if (props.isSelected) {
    return (
      <div className="relative h-[240px] w-full max-w-400 shrink-0 rounded-18 bg-gray-light p-18 mxs:max-w-full">
        <img
          className="absolute bottom-8 right-14 h-[176px] w-[179px]"
          src="/images/program/start-lesson.png"
          alt=""
        />

        <div className="relative flex h-full flex-col">
          <div className="text-12 font-extrabold capitalize text-black opacity-30">
            <FormattedMessage id="Lesson" /> {props.index}
          </div>

          <div className="mt-2 line-clamp-2 overflow-hidden text-ellipsis break-words text-18 font-bold leading-5 text-black">
            {props.title}
          </div>

          <LinkButton
            to={urls.lesson(props.tag)}
            size="custom"
            bg="black-gradient"
            className="mt-auto h-44 max-w-[140px] text-16 font-bold"
          >
            <FormattedMessage
              id={
                props.isCompleted
                  ? 'Again'
                  : props.inProgress
                  ? 'Continue'
                  : 'Start lesson'
              }
            />
          </LinkButton>
        </div>
      </div>
    )
  }

  return (
    <div
      className="relative flex w-full max-w-400 cursor-pointer items-baseline rounded-18 bg-gray-light p-16 mxs:max-w-full"
      onClick={() => {
        store.setSelectedLesson(props.id)
      }}
    >
      <div className="absolute right-10 top-10 text-12 font-semibold opacity-40">
        {props.type_name}
      </div>

      <div className="flex w-full items-center gap-12">
        <div
          className={cn(
            'relative flex size-80 shrink-0 items-center justify-center rounded-full border-2 p-6',
            props.isCompleted
              ? 'border-green bg-green-40'
              : 'border-lesson-icon-border',
          )}
        >
          <div
            className={cn(
              'flex size-full items-center justify-center overflow-hidden rounded-full',
              props.isCompleted ? 'bg-green-40' : 'bg-white',
            )}
          >
            <img className="w-half" src={lessonTypeIcons[props.type]} alt="" />
          </div>

          {props.isCompleted && (
            <div className="absolute -bottom-2 -left-2 flex size-24 items-center justify-center rounded-full border-2 border-white bg-green">
              <svg
                width="10"
                height="8"
                viewBox="0 0 10 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 4.50033L2.48217 6.53037C2.94672 7.16663 3.90024 7.15427 4.34816 6.50616L8.15346 1"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          )}
        </div>

        <div>
          <div className="text-12 font-extrabold capitalize text-black opacity-30">
            <FormattedMessage id="Lesson" /> {props.index}
          </div>

          <div className="mt-2 line-clamp-2 overflow-hidden text-ellipsis break-words text-18 font-bold leading-5 text-black">
            {props.title}
          </div>
        </div>
      </div>
    </div>
  )
}
