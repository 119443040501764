import { useAppModule } from '../appContext.ts'
import { Link, useNavigate } from 'react-router-dom'
import { getFormObject, useMount } from '../../shared/lib/utils.ts'
import { Toasts } from '../toasts/toasts.tsx'
import { urls } from '../../shared/urls.ts'
import { useSnapshot } from 'valtio/react'
import { FormattedMessage } from 'react-intl'
import { Button, LinkButton } from '../../shared/ui/button/button.tsx'
import { Spinner } from '../../shared/ui/spinner.tsx'
import { TextInput } from '../../shared/ui/textInput/textInput.tsx'
import { Tabs } from '../../shared/ui/tabs/tabs.tsx'
import { Icon } from '../../shared/ui/icon/icon.tsx'
import React, { useEffect } from 'react'
import { LangSwitcher } from '../appHeader/appHeader.tsx'
import { ContactsBlock } from '../contacts/contactsBlock.tsx'

const termsUrl = 'https://img.edman.ai/pdf/terms.pdf'
const recurrentTermsUrl = 'https://img.edman.ai/pdf/recurrent.pdf'
export function Login(props: { registration: boolean }) {
  const authStore = useAppModule().authStore
  useMount(() => {
    void authStore.saveMarkData()
    authStore.trackEvent('registration_page_load')
  })
  const navigate = useNavigate()
  return (
    <div className="size-full min-h-dvh px-16">
      <div
        data-testid="login-page"
        className="relative mx-auto max-w-400 pt-[15vh]"
      >
        <div className="absolute left-0 top-8 pt-12 sm:-top-2">
          <LinkButton
            bg="violet"
            className="whitespace-nowrap"
            rounded="full"
            to={urls.subscriptions}
          >
            <FormattedMessage id="Plans" />
          </LinkButton>
        </div>
        <LangSwitcher darkBorder top className="pt-12" />
        <img
          className="mx-auto mb-16 w-300"
          src="/images/logo-black.svg"
          alt="logo"
        />

        <Tabs.Root defaultValue={props.registration ? 'registration' : 'login'}>
          <Tabs.List>
            <Tabs.Trigger value="login">
              <Link to={urls.login}>
                <FormattedMessage id="login" />
              </Link>
            </Tabs.Trigger>
            <Tabs.Trigger value="registration">
              <Link to={urls.registration}>
                <FormattedMessage id="registration" />
              </Link>
            </Tabs.Trigger>
          </Tabs.List>

          <Tabs.Content value="login">
            <RegLoginForm
              onSubmit={(data) => {
                void authStore.login(data.login, data.password).then(() => {
                  navigate('/')
                })
              }}
              login={true}
              btnText={<FormattedMessage id="login" />}
            />
          </Tabs.Content>

          <Tabs.Content value="registration">
            <RegLoginForm
              onSubmit={(data) => {
                void authStore
                  .registration(data.login, data.password)
                  .then((user) => {
                    if (user.account_confirmed) {
                      navigate(urls.onboarding())
                    } else {
                      navigate(urls.confirm)
                    }
                  })
              }}
              login={false}
              btnText={<FormattedMessage id="registration" />}
            />
          </Tabs.Content>
        </Tabs.Root>
        <div className="my-16">
          <FormattedMessage id="sendQuestions" />:
        </div>
        <ContactsBlock full />

        <Toasts />
      </div>
    </div>
  )
}

interface RegistrationProps {
  btnText: React.ReactNode
  login: boolean
  registrationWithoutPassword?: boolean
  disableButton?: boolean
  recurrentText?: React.ReactNode
  onSubmit: (data: { login: string; password: string }) => void
}

export function RegLoginForm(props: RegistrationProps) {
  const authStore = useAppModule().authStore
  const state = useSnapshot(authStore.state)
  const prefix = props.login ? 'login-' : 'reg-'

  useEffect(() => {
    authStore.setRecurrent(!props.registrationWithoutPassword)
    authStore.setAutopay(!props.registrationWithoutPassword)
  }, [authStore, props.registrationWithoutPassword])

  const emailInputId = props.registrationWithoutPassword
    ? 'subscriptions-email-input'
    : undefined

  const buttonId = props.registrationWithoutPassword
    ? 'subscriptions-reg-button'
    : undefined
  return (
    <form
      className="w-full py-16"
      onSubmit={(e) => {
        e.preventDefault()
        const data = getFormObject(e.target as HTMLFormElement) as {
          login: string
          password: string
        }
        props.onSubmit(data)
      }}
    >
      <div className="flex w-full flex-col gap-4">
        {props.registrationWithoutPassword ? (
          <div className="pl-20 text-14 font-extrabold text-main-light">
            <FormattedMessage id="Please enter your email" />
          </div>
        ) : (
          <span>Email</span>
        )}

        <TextInput
          type="email"
          id={emailInputId}
          placeholder="example@yourmail.ru"
          name="login"
          required={true}
          className="w-full text-default"
        />
      </div>

      {!props.registrationWithoutPassword && (
        <div className="mt-16 flex w-full  flex-col gap-4">
          <span>
            <FormattedMessage id="password" />
          </span>
          <TextInput
            type="password"
            minLength={6}
            name="password"
            data-testid={prefix + 'password'}
            required={true}
            className="w-full text-default"
          />
        </div>
      )}

      <div className="mt-8">
        {props.registrationWithoutPassword && (
          <>
            <FormCheckbox
              checked={state.recurrent}
              onChange={(e) => {
                authStore.setRecurrent(e.target.checked)
              }}
            >
              {props.recurrentText}
            </FormCheckbox>

            <FormCheckbox
              checked={state.autoPay}
              onChange={(e) => {
                authStore.setAutopay(e.target.checked)
              }}
            >
              <div>
                <FormattedMessage
                  id="recurrentTerms"
                  values={{
                    btnText: props.btnText,
                    termsLink: (
                      <a
                        target="_blank"
                        rel="noreferrer"
                        className="underline"
                        href={recurrentTermsUrl}
                      >
                        <FormattedMessage id="recurrentTermsLinkName" />
                      </a>
                    ),
                  }}
                />
              </div>
            </FormCheckbox>
          </>
        )}
      </div>

      {!props.login && (
        <FormCheckbox
          checked={state.termsAgree}
          onChange={(e) => {
            authStore.setTermsAgree(e.target.checked)
          }}
        >
          <div>
            <FormattedMessage
              id={props.registrationWithoutPassword ? 'terms' : 'termsLogin'}
              values={{
                btnText: props.btnText,
                termsLink: (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    className="underline"
                    href={termsUrl}
                  >
                    <FormattedMessage id="termsLinkName" />
                  </a>
                ),
                recurrentLink: (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    className="underline"
                    href={recurrentTermsUrl}
                  >
                    <FormattedMessage id="recurrentTermsLinkName" />
                  </a>
                ),
              }}
            />
          </div>
        </FormCheckbox>
      )}

      <Button
        bg="violet"
        id={buttonId}
        className="relative mt-20 flex w-full justify-center"
        size="large"
        rounded="full"
        disabled={
          !(
            props.login ||
            (state.termsAgree && state.recurrent && state.autoPay)
          ) ||
          state.loading ||
          props.disableButton
        }
        data-testid={prefix + 'btn'}
        type="submit"
      >
        {state.loading ? (
          <Spinner size="medium" className="text-gray-dark" />
        ) : (
          props.btnText
        )}
        {props.registrationWithoutPassword && (
          <div className="absolute inset-y-0 right-12 flex items-center">
            <Icon iconName="arrowRight" />
          </div>
        )}
      </Button>
    </form>
  )
}

function FormCheckbox(props: {
  checked: boolean
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  children: React.ReactNode
}) {
  return (
    <label className="flex items-start gap-8 px-8 pt-8 text-14 leading-tight">
      <input
        type="checkbox"
        className="mr-4 mt-2 inline-block shrink-0 scale-125 accent-violet"
        checked={props.checked}
        onChange={props.onChange}
      />{' '}
      {props.children}
    </label>
  )
}
