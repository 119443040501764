import { classed } from '@tw-classed/react'

export const Card = classed.div('overflow-hidden rounded p-24', {
  variants: {
    bg: {
      white: 'bg-white',
      // eslint-disable-next-line sonarjs/no-duplicate-string
      'purple-light': 'bg-purple-light',
      gray: 'bg-purple-light',
    },
  },
  defaultVariants: {
    bg: 'white',
  },
})

export const Badge = classed.div(
  'items-center overflow-hidden whitespace-nowrap px-16 text-14 leading-4',
  {
    variants: {
      inline: {
        true: 'inline-flex',
        false: 'flex',
      },
      bg: {
        gray: 'bg-gray-light',
        'gray-dark': 'bg-gray-dark text-white',
        alert: 'bg-alert text-white',
        yellow: 'bg-yellow',
        main: 'bg-main text-white',
        second: 'bg-second',
        'alert-light': 'bg-alert-light text-alert',
        'purple-light': 'bg-purple-light',
        orange: 'bg-orange',
        green: 'bg-green',
        'green-light': 'bg-green-light text-green-dark',
        white: 'bg-white',
        violetd: 'bg-violetd text-white',
        violet: 'bg-violet text-white',
        teal: 'bg-teal text-white',
      },
      size: {
        small: 'h-28 text-12 font-bold',
        large: 'h-32',
      },
      rounded: {
        true: 'rounded-full',
        false: 'rounded-4',
      },
    },
    defaultVariants: {
      size: 'large',
      rounded: true,
      inline: false,
    },
  },
)
