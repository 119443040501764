import {
  createStoreContext,
  useIsMobile,
  useStoreContext,
} from '../../shared/lib/hooks.ts'
import { Link, useNavigate } from 'react-router-dom'
import { isWebDomain, urls } from '../../shared/urls.ts'
import { cn, sortBy } from '../../shared/lib/utils.ts'
import React, { Fragment, useState } from 'react'
import { Txt } from '../../shared/ui/text/text.tsx'
import { ProgramStore } from './programStore.ts'
import { useSnapshot } from 'valtio/react'
import { ProgramItem } from '../../shared/api/chatApi.ts'
import { Icon } from '../../shared/ui/icon/icon.tsx'
import { Button, LinkButton } from '../../shared/ui/button/button.tsx'
import { classed } from '@tw-classed/react'
import { Popover } from '../../shared/ui/popover/popover.tsx'
import { Dialog } from '../../shared/ui/dialog/dialog.tsx'
import { SubscriptionState } from '../subscriptions/subscriptionService.ts'
import { formatDate } from '../../shared/lib/stringUtils.ts'
import { WithTooltip } from '../../shared/ui/tooltip/tooltip.tsx'
import ContentLoader from 'react-content-loader'
import { is } from '../../shared/lib/taggedUnion.ts'
import { FormattedMessage, useIntl } from 'react-intl'
import Joyride, { EVENTS } from 'react-joyride'
import { ImageHideBeforeLoad } from '../../shared/ui/images/images.tsx'
import { useAppModule } from '../appContext.ts'
import { BuyTrial } from './buyTrial.tsx'

const tourSteps = [
  {
    target: '#tour-program-modules',
    placement: 'top' as const,
    content: 'These are your program modules',
    disableBeacon: true,
  },
  {
    target: '#tour-lessons',
    placement: 'top' as const,
    content: 'These are your lessons',
    disableBeacon: true,
  },
  {
    placement: 'right' as const,
    target: '#tour-profile',
    content: 'This is your profile',
    disableBeacon: true,
  },
]

export const ProgramContext = createStoreContext<ProgramStore>()

export function useProgramStore() {
  return useStoreContext(ProgramContext)
}

function useStoreState() {
  const store = useProgramStore()
  return useSnapshot(store.state)
}

const lessonTypeImages: Record<string, React.ReactElement | undefined> = {
  'General conversation': (
    <img alt="conversation" src="/images/conversation.jpg" />
  ),
  Grammar: <img alt="grammar" src="/images/grammar.jpg" />,
  Vocabulary: <img alt="vocabulary" src="/images/vocabulary.jpg" />,
  Roleplay: <img alt="review" src="/images/roleplay.jpg" />,
}

export function ProgramView() {
  const store = useProgramStore()
  const state = useSnapshot(store.state)
  const isMobile = useIsMobile()

  if (state.loading) {
    return <ProgramLoader />
  }

  return (
    <div className="mx-auto max-w-screen-xl p-8 sm:p-60">
      {store.registration && <SelectLevelDialog />}
      <SubscriptionStateView />
      {state.showTour && (
        <Joyride
          disableOverlayClose
          hideBackButton
          locale={{ last: 'Finish' }}
          showSkipButton
          stepIndex={state.tourStepIndex}
          hideCloseButton
          callback={(state) => {
            if (
              (
                [EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND] as string[]
              ).includes(state.type)
            ) {
              store.nextTourStep()
            } else if (EVENTS.TOUR_END == state.type) {
              store.endTour()
            }
            if (state.status == 'finished') {
              store.hideTour()
            }
          }}
          continuous={true}
          steps={tourSteps}
        />
      )}
      <BuyTrial />
      {isMobile ? <MobileProgramView /> : <DesktopProgramView />}
    </div>
  )
}

function SubscriptionStateView() {
  const store = useProgramStore()
  const state = useSnapshot(store.state)
  const intl = useIntl()
  if (is(state.subscriptionState, 'active')) {
    return null
  }
  const getDescription = SubscriptionState.partialMatch(
    {
      inactive: () => intl.formatMessage({ id: 'subscription.inactive' }),
      soonExpired: (x) =>
        intl.formatMessage(
          { id: 'subscription.soonExpired' },
          { date: formatDate(x.date) },
        ),
    },
    () => '',
  )

  return (
    <Dialog.Root defaultOpen={true}>
      <Dialog.Content
        className="max-w-400"
        closable={!is(state.subscriptionState, 'inactive')}
      >
        <div className="mb-12">{getDescription(state.subscriptionState)}</div>
        <LinkButton
          rounded="full"
          bg="violet"
          className="mx-auto w-fit"
          to={urls.subscriptions}
        >
          <FormattedMessage id="Get subscription" />
        </LinkButton>
      </Dialog.Content>
    </Dialog.Root>
  )
}

export function DesktopProgramView() {
  return (
    <div>
      <Header />
      <div className="mt-32 flex gap-20">
        <Modules full={false} />
      </div>
      <SelectedProgram />
      <CyclesBlock />
    </div>
  )
}

export function MobileProgramView() {
  const state = useStoreState()
  return (
    <>
      <WellcomeText />
      <TopicStats className="my-16" />
      <Modules full={false} />
      <MobileCycles />

      {state.selectedCycle && <Cycle items={state.selectedCycle.items} />}
      <div className="mb-120"></div>
      <BottomMobile />
    </>
  )
}

function MobileCycles() {
  const state = useStoreState()
  const [open, setOpen] = useState(false)
  return (
    <div>
      <MobileHeader>
        <FormattedMessage id="Lesson" />:
      </MobileHeader>
      <Popover.Root open={open} onOpenChange={setOpen}>
        <Popover.Trigger asChild>
          <div className="flex items-center justify-between rounded bg-purple-light p-16 text-20 font-bold">
            {state.currentCycle?.title}
            <Icon iconName="unfold" />
          </div>
        </Popover.Trigger>
        <Popover.Content
          align="end"
          side="top"
          sideOffset={8}
          className="w-300"
        >
          <Cycles />
          <Popover.Portal>
            <div
              onClick={(e) => {
                e.stopPropagation()
                setOpen(false)
              }}
              className="fixed inset-0 bg-dark/20"
            ></div>
          </Popover.Portal>
        </Popover.Content>
      </Popover.Root>
    </div>
  )
}

function BottomMobile() {
  return (
    <div className="fixed inset-x-0 bottom-0 flex flex-col items-center border-t-alert/30 bg-white p-16 shadow-edge">
      <ContinueLesson className="w-full" />
      <LastLesson />
    </div>
  )
}
function ContinueLesson(props: { className?: string }) {
  const navigate = useNavigate()
  const state = useStoreState()
  if (!state.currentProgram) {
    return null
  }
  const tag = state.currentProgram.tag
  return (
    <Button
      bg="main-dark"
      className={props.className}
      onClick={() => {
        navigate(urls.lesson(tag))
      }}
    >
      <FormattedMessage id="Continue lesson" />
    </Button>
  )
}

export function WellcomeText() {
  const state = useStoreState()
  const intl = useIntl()
  const friend = intl.formatMessage({ id: 'Friend' })
  const name = isWebDomain ? friend : state.name
  return (
    <>
      <div className="flex">
        <div className="max-w-700 align-middle text-24 font-semibold sm:text-32 sm:font-bold">
          <FormattedMessage id="program.hello" values={{ name }} />
          <span className="ml-16  whitespace-nowrap rounded-4 bg-orange px-4 py-2 align-middle text-14 font-bold">
            Level: {state.level}{' '}
          </span>
        </div>
        <img
          src="/images/edman.jpg"
          className="size-100 shrink-0 overflow-hidden rounded-full sm:hidden"
          alt="Edman"
        />
      </div>
      <div className="mb-36 mt-4 max-w-300 text-14">
        <FormattedMessage id="program.course" />
      </div>
    </>
  )
}

function LastLesson() {
  const state = useStoreState()
  return (
    <div className="mt-8 text-14 text-light">
      <FormattedMessage id="Last lesson" />: {state.currentCycle?.title ?? ''}
    </div>
  )
}

export function EdmanAvatar(props: {
  className?: string
  hemisphere?: boolean
}) {
  const file = props.hemisphere ? 'edman_hemisphere' : 'edman'
  const [width, height] = props.hemisphere ? [480, 270] : [260, 260]
  const root = 'https://img.edman.ai'
  const mp4 = `${root}/videos/${file}.mp4`
  const webm = `${root}/videos/${file}.webm`
  return (
    <video
      autoPlay
      loop
      preload="auto"
      width={width}
      height={height}
      muted={true}
      className={props.className}
      playsInline
    >
      <source src={mp4} type='video/mp4; codecs="hvc1"' />
      <source src={webm} type="video/webm" />
    </video>
  )
}

function Header() {
  return (
    <div className="flex items-center gap-32">
      <div className="w-260 shrink-0">
        <EdmanAvatar className="size-260" />
      </div>
      <div>
        <WellcomeText />
        <ContinueLesson />
        <LastLesson />
      </div>
    </div>
  )
}

function StatBlock(props: { title: React.ReactNode; value: string }) {
  return (
    <div>
      <div className="text-end text-24 font-semibold leading-none">
        {props.value}
      </div>
      <div className="text-end text-12 font-bold text-light">{props.title}</div>
    </div>
  )
}

function TopicStats(props: { className?: string }) {
  const state = useStoreState()
  const separator = <div className="mx-20 border-r border-gray"></div>
  const lessonNumber = state.currentCycle?.index ?? 0
  const count = state.selectedCycles.length
  return (
    <div className={cn('flex justify-end', props.className)}>
      <StatBlock
        title={<FormattedMessage id="new words learned" />}
        value={(
          state.stat.vocab.find((x) => x.program == state.selectedProgram)
            ?.learned_count ?? 0
        ).toFixed(0)}
      />
      {separator}
      <StatBlock
        title={<FormattedMessage id="program.lessons" />}
        value={`${lessonNumber}/${count}`}
      />
      {separator}
      <StatBlock title={<FormattedMessage id="program.hours" />} value="0/0" />
    </div>
  )
}

function SelectedProgram() {
  const state = useStoreState()
  return (
    <div className="mb-24 mt-48 flex flex-col justify-between border-b border-gray sm:flex-row">
      <Txt kind="header">
        <FormattedMessage id="Topic" />: {state.selectedProgram}
      </Txt>
      <TopicStats />
    </div>
  )
}

const Lesson = classed.div(
  'flex cursor-pointer items-center gap-4 text-18 leading-4',
  {
    variants: {
      variant: {
        current: '',
        review: 'text-yellow-dark',
        regular: '',
      },
    },
    compoundVariants: [
      {
        variant: ['regular', 'review'],
        className: '-ml-16 px-16 py-8',
      },
      {
        variant: 'current',
        className: '-ml-16 rounded-full bg-dark px-16 py-8 text-white',
      },
    ],
  },
)

function Cycles() {
  const store = useProgramStore()
  const state = useStoreState()
  return (
    <div className="ml-24 flex max-w-300 flex-col items-start gap-8 sm:ml-0 sm:shrink-0">
      {state.selectedCycles.map((lesson, i) => {
        const current = state.selectedCycleTitle == lesson.title
        const review = lesson.items.some((x) => x.lesson_type == 'Review')
        const variant = current ? 'current' : review ? 'review' : 'regular'
        return (
          <Lesson
            onClick={() => {
              store.setSelectedCycleTitle(lesson.title)
            }}
            variant={variant}
            key={i}
          >
            {lesson.title}
          </Lesson>
        )
      })}
    </div>
  )
}

function CyclesBlock() {
  const state = useStoreState()
  return (
    <div>
      <div className="mb-8 text-24 font-semibold">
        <FormattedMessage id="subtopics" />:
      </div>
      <div className="flex gap-80">
        <Cycles />
        <div className="flex max-w-800 flex-col items-start justify-start sm:w-full">
          {state.selectedCycle && <Cycle items={state.selectedCycle.items} />}
        </div>
      </div>
    </div>
  )
}

function Cycle(props: { items: readonly ProgramItem[] }) {
  const state = useStoreState()
  const header = (
    <MobileHeader>
      <FormattedMessage id="lessons" />:
    </MobileHeader>
  )
  return (
    <div className="w-full">
      {header}
      <div id="tour-lessons" className="mb-16 flex w-full flex-wrap gap-16">
        {sortBy(props.items, (x) => x.id).map((x, i) => {
          return (
            <Fragment key={x.id}>
              <div className="w-160">
                <div className="relative mx-auto w-fit">
                  {x.status == 'completed' && (
                    <div className="absolute bottom-12 right-12 rounded-full bg-white text-green">
                      <Icon iconName="check" />
                    </div>
                  )}
                  <div className="min-w-0 cursor-pointer overflow-hidden rounded border-8 border-main-dark/10">
                    <Link to={urls.lesson(x.tag)}>
                      {lessonTypeImages[x.lesson_type] ??
                        lessonTypeImages['General conversation']}
                    </Link>
                  </div>
                </div>
                <div className="mt-8 pl-8 font-bold sm:mt-16">
                  {i + 1}. {x.lesson_type}
                </div>

                {state.currentProgram?.tag == x.tag && (
                  <ContinueLesson
                    className={cn(
                      'mx-auto mt-16 hidden sm:block',
                      (state.selectedCycleTitle != state.currentCycle?.title ||
                        state.selectedProgramIsLocked) &&
                        'invisible',
                    )}
                  />
                )}
              </div>
            </Fragment>
          )
        })}
      </div>
    </div>
  )
}

function GoalStats(props: { className: string }) {
  function row(progress: number, text: React.ReactNode, lock = true) {
    return (
      <div className="text-light">
        <WithTooltip
          disabled={!lock}
          tooltip={<FormattedMessage id="indicatorAvailable" />}
        >
          <div className="mb-4 flex items-center">
            {lock && <Icon iconName="lock" className="-ml-4" />}
            <div className="text-14">{text}</div>
          </div>
        </WithTooltip>
        <ProgressBar value={progress} alert />
      </div>
    )
  }

  return (
    <div
      className={cn(
        'mt-20 flex w-full flex-col justify-center gap-12 sm:w-200',
        props.className,
      )}
    >
      {row(0, <FormattedMessage id="Can speak about my work" />)}
      {row(0, <FormattedMessage id="Can speak with friends" />)}
      {row(0, <FormattedMessage id="Can speak on basic topics" />)}
    </div>
  )
}

function Modules(props: { full: boolean }) {
  const store = useProgramStore()
  const state = useStoreState()
  const isMobile = useIsMobile()

  return (
    <div
      className={cn(
        'flex w-full flex-col items-start sm:flex-row',
        props.full
          ? 'absolute  inset-x-0 top-0 z-10 cursor-pointer overflow-hidden rounded bg-purple shadow-menu'
          : 'relative',
      )}
    >
      <div
        id="tour-program-modules"
        onClick={(e) => {
          e.stopPropagation()
          store.toggleModulesOpen()
        }}
        className={cn(
          'w-full flex-1 cursor-pointer px-16 pb-32 pt-16 sm:px-32 ',
          !props.full && 'rounded bg-purple',
        )}
      >
        <div className="flex flex-col items-start sm:flex-row ">
          <div className={cn('sm:flex-1')}>
            <div className="text-32 font-bold">{state.selectedLevel}</div>
            <div className="text-14 font-semibold">
              <FormattedMessage id="Current topic" />
            </div>
            <div
              className={cn(
                'flex w-fit cursor-pointer items-center  justify-between gap-8 text-18 font-bold leading-tight',
                props.full && 'max-w-200',
              )}
            >
              <span>{state.selectedProgram}</span>
              <Icon iconName="unfold" />
            </div>
            {props.full && (
              <div className="mt-8 gap-16 text-14 font-bold">
                {state.programTree
                  .find((x) => x.level === state.selectedLevel)
                  ?.items.map((x) => (
                    <div
                      onClick={() => {
                        store.setSelectedProgram(x.program)
                      }}
                      key={x.program}
                    >
                      {x.program}
                    </div>
                  ))}
              </div>
            )}
          </div>
          {props.full && (
            <>
              {state.programTree
                .filter((x) => x.level !== state.selectedLevel)
                .map((levelBlock) => {
                  return (
                    <div
                      className={cn('msm:mt-16 sm:flex-1')}
                      key={levelBlock.level}
                    >
                      <div className="text-32 font-bold">
                        {levelBlock.level}
                      </div>
                      <div className="mt-8 gap-16 text-14 font-bold">
                        {state.programTree
                          .find((x) => x.level === levelBlock.level)
                          ?.items.map((x) => (
                            <div
                              onClick={() => {
                                store.setSelectedProgram(x.program)
                              }}
                              key={x.program}
                            >
                              {x.program}
                            </div>
                          ))}
                      </div>
                    </div>
                  )
                })}
            </>
          )}
        </div>
        {state.modulesOpened && !props.full && (
          <>
            <div className="fixed inset-0 bg-dark/20"></div>
            <Modules full={true} />
          </>
        )}
      </div>
      {isMobile ? <GoalMobile full={props.full} /> : <Goal full={props.full} />}
    </div>
  )
}

function Goal(props: { full: boolean }) {
  const state = useStoreState()
  const goal = isWebDomain ? (
    <FormattedMessage id="program.webGoal" />
  ) : (
    <>
      <span className="text-14 font-bold text-light">
        <FormattedMessage id="Your goal" />:{' '}
      </span>{' '}
      <span className="text-20 font-bold">{state.info.goal}</span>
    </>
  )
  return (
    <div className="flex shrink-0 flex-col rounded px-32 pb-32 pt-16">
      <div className="mb-16 max-w-200 text-14 font-bold leading-tight">
        {goal}
      </div>
      <div className="mt-8 flex flex-1 items-center justify-center align-middle text-[120px] leading-none">
        🎯
      </div>
      <GoalStats className={cn(!props.full && 'hidden')} />
    </div>
  )
}

const MobileHeader = classed.div(
  'mb-8 mt-16 text-14 font-bold text-light sm:hidden',
)
function GoalMobile(props: { full: boolean }) {
  const state = useStoreState()
  return (
    <div className={cn('w-full', props.full && 'p-16')}>
      <MobileHeader>
        <FormattedMessage id="Your goal" />:
      </MobileHeader>
      <div
        className={cn(
          'flex w-full items-center gap-8',
          !props.full && 'rounded bg-purple-light p-8 ',
        )}
      >
        <div className="mt-8 flex items-center justify-center align-middle text-[40px] leading-none">
          🎯
        </div>
        <span className="text-20 font-bold">{state.info.goal}</span>
      </div>
      <GoalStats className={cn(!props.full && 'hidden sm:invisible')} />
    </div>
  )
}

interface ProgressBarProps {
  value: number
  alert?: boolean
  className?: string
}

const ProgressBar = (props: ProgressBarProps) => {
  return (
    <div className={cn('h-8 rounded-full bg-purple-light', props.className)}>
      <div
        className={cn(
          'h-full rounded-full',
          props.alert ? 'bg-alert' : ' bg-purple3',
        )}
        style={{ width: props.value + '%' }}
      ></div>
    </div>
  )
}

const ProgramLoader = () => (
  <ContentLoader
    speed={1}
    style={{ width: '100%' }}
    viewBox="0 0 600 340"
    className="mx-auto max-w-screen-xl"
    foregroundColor="#D9D9FF"
    backgroundColor="#DCE3FA"
  >
    <circle cx="73" cy="76" r="57" />
    <rect x="149" y="17" rx="0" ry="0" width="288" height="18" />
    <rect x="150" y="45" rx="0" ry="0" width="91" height="12" />
    <rect x="151" y="64" rx="0" ry="0" width="139" height="17" />
    <rect x="151" y="99" rx="0" ry="0" width="63" height="18" />
    <rect x="150" y="119" rx="0" ry="0" width="131" height="14" />
    <rect x="14" y="149" rx="0" ry="0" width="450" height="115" />
    <circle cx="529" cy="208" r="31" />
    <rect x="482" y="157" rx="0" ry="0" width="75" height="12" />
  </ContentLoader>
)

const LevelButtonContainer = classed.button(
  'flex h-80 w-full max-w-400 cursor-pointer items-center gap-12 overflow-hidden rounded-8 border border-purple bg-white px-16 py-12 text-left text-12 font-medium leading-tight text-default outline-none',
)

function SelectLevelDialog() {
  return (
    <Dialog.Root open={true}>
      <Dialog.Content
        overlayBlur
        className="max-w-[min(95%,400px)]"
        closable={false}
      >
        <SelectLevel />
      </Dialog.Content>
    </Dialog.Root>
  )
}

function SelectLevel() {
  const store = useProgramStore()
  const appStore = useAppModule().appStore
  const authStore = useAppModule().authStore
  const navigate = useNavigate()

  function block(level: 'A2' | 'B1' | 'B2') {
    const names = {
      A2: 'onboarding.a2',
      B1: 'onboarding.b1',
      B2: 'onboarding.b2',
    } as const
    const name = names[level]
    return (
      <LevelButtonContainer
        key={level}
        onClick={() => {
          authStore.trackMetrika('demo2_level_chosen')
          void authStore.registerAnon(level).then(() => {
            if (level == 'A2') {
              appStore.setLanguage('ru')
            }
            navigate('/')
          })
          store.setLoading(true)
        }}
      >
        <ImageHideBeforeLoad
          className="size-56 shrink-0"
          src={`/images/level-${level}.svg`}
        />
        <FormattedMessage id={name} />
      </LevelButtonContainer>
    )
  }

  const levels = ['A2', 'B1', 'B2'] as const
  return (
    <div className="flex flex-col items-center gap-16 px-20 py-12">
      <div className="text-24 font-bold text-white">
        <FormattedMessage id="demo.typeLevel" />
      </div>
      <div className="flex flex-col items-center gap-16">
        {levels.map((level) => block(level))}
      </div>
    </div>
  )
}
