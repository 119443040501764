import React, {
  createContext,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import { subscribe } from 'valtio'
import { subscribeKey } from 'valtio/utils'
import { debounce } from './utils.ts'

export type DataState<T> = 'loading' | 'error' | T

export function isDataLoaded<T>(dataState: DataState<T>): dataState is T {
  return dataState != 'loading' && dataState != 'error'
}

const emptyStoreContext = Symbol()

export function createStoreContext<T>() {
  return createContext<T>(emptyStoreContext as never)
}

export function useStoreContext<T>(context: React.Context<T>) {
  const store = useContext(context)
  if (store === emptyStoreContext) {
    throw new Error('Component must be wrapped with StoreContext.Provider')
  }
  return store
}

export function usePromise<T>(promise: () => Promise<T>) {
  const [state, setState] = useState<DataState<T>>('loading')
  useEffect(() => {
    promise()
      .then((x) => {
        setState(x)
      })
      .catch((e) => {
        setState('error')
        return Promise.resolve(e)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return state
}

const initialRef = Symbol()
export function useLazyRef<T>(initializer: () => T) {
  const ref = useRef<T>(initialRef as never)
  if (ref.current === initialRef) {
    ref.current = initializer()
  }
  return ref
}

export function useSubscribe<T extends object>(
  proxyObject: T,
  callback: () => void,
  notifyInSync?: boolean,
) {
  useEffect(() => {
    return subscribe(proxyObject, callback, notifyInSync)
  }, [callback, notifyInSync, proxyObject])
}

export function useSubscribeKey<T extends object, K extends keyof T>(
  proxyObject: T,
  key: K,
  callback: (value: T[K]) => void,
  notifyInSync?: boolean,
) {
  useEffect(() => {
    return subscribeKey(proxyObject, key, callback, notifyInSync)
  }, [callback, key, notifyInSync, proxyObject])
}

const mobileWidth = 800
export function isMobile() {
  return window.innerWidth <= mobileWidth
}

export function useTimeout(callback: () => void, delay: number | null): void {
  const savedCallback = useRef(callback)

  useLayoutEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    if (!delay && delay !== 0) {
      return
    }

    const id = setTimeout(() => {
      savedCallback.current()
    }, delay)

    return () => {
      clearTimeout(id)
    }
  }, [delay])
}

export const useIsMobile = () => {
  const [width, setWidth] = useState(window.innerWidth)

  useLayoutEffect(() => {
    const handleWindowSizeChange = debounce(() => {
      setWidth(window.innerWidth)
    }, 300)
    window.addEventListener('resize', handleWindowSizeChange)

    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [setWidth])

  return width <= mobileWidth
}

export function useDelay(ms: number): boolean {
  const [ready, setReady] = useState(false)
  useTimeout(() => {
    setReady(true)
  }, ms)
  return ready
}
