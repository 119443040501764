import { useAppModule } from '../appContext.ts'
import { Link, useNavigate } from 'react-router-dom'
import { urls } from '../../shared/urls.ts'
import { Icon } from '../../shared/ui/icon/icon.tsx'
import { cn } from '../../shared/lib/utils.ts'
import {
  Drawer,
  DrawerContent,
  DrawerTrigger,
} from '../../shared/ui/drawer/drawer.tsx'
import React from 'react'
import { useSnapshot } from 'valtio/react'
import { LinkButton } from '../../shared/ui/button/button.tsx'
import { FormattedMessage } from 'react-intl'
import { ContactsBlock } from '../contacts/contactsBlock.tsx'

export function MobileMenuBotton() {
  return (
    <Drawer direction="left">
      <DrawerTrigger asChild>
        <Icon iconName="menu" />
      </DrawerTrigger>
      <DrawerContent className="bg-white" direction="left">
        <Menu />
      </DrawerContent>
    </Drawer>
  )
}

function Demo() {
  return (
    <div className="-mx-16 mb-48 flex flex-1 flex-col justify-end sm:hidden">
      <div className="text-16 font-semibold">
        <FormattedMessage id="demoDescription" />
      </div>
      <LinkButton
        bg="violet"
        rounded="full"
        size="medium"
        className="mt-16"
        to={urls.subscriptions}
      >
        <FormattedMessage id="Choose plan" />
      </LinkButton>
    </div>
  )
}

export const Menu = (props: { className?: string }) => {
  const authStore = useAppModule().authStore
  const authState = useSnapshot(authStore.state)
  const isAnonOrLogout = authState.isAnon || !authState.isLogin
  const programLink = (
    <Link
      className="flex items-center gap-12 whitespace-nowrap"
      to={urls.program}
    >
      <Icon className="shrink-0" iconName="learning" />{' '}
      <FormattedMessage id="Learning profile" />
    </Link>
  )
  const subscriptionsLink = (
    <Link
      className="flex items-center gap-12 whitespace-nowrap"
      onClick={() => {
        authStore.trackEvent('click_plans_demo_onboarding')
      }}
      to={urls.subscriptions}
    >
      <Icon className="shrink-0" iconName="learning" />{' '}
      <FormattedMessage id="Plans" />
    </Link>
  )
  return (
    <div
      className={cn(
        'flex min-h-dvh w-[230px] shrink-0 cursor-default flex-col bg-violetd px-40 py-32 pb-40 font-bold text-white',
        props.className,
      )}
    >
      <div className="mb-40">
        <div className="w-fit">
          <Link to="/" className="mb-8 inline-block text-20 font-extrabold">
            <img src="/images/logo.svg" alt="logo" />
          </Link>
        </div>
        <div className="mt-28 flex flex-col gap-20 font-bold">
          {isAnonOrLogout ? subscriptionsLink : programLink}
          <Link
            className="hidden items-center gap-12 whitespace-nowrap sm:flex"
            onClick={() => {
              authStore.trackEvent('click_help_demo_onboarding')
            }}
            to={urls.contacts}
          >
            <Icon className="shrink-0" iconName="support" />{' '}
            <FormattedMessage id="contacts.title" />
          </Link>
        </div>
      </div>
      {isAnonOrLogout ? (
        <Demo />
      ) : (
        <>
          <div className="mb-16 text-10 font-bold uppercase text-light">
            <FormattedMessage id="Option" />
          </div>
          <div className="flex flex-col gap-20">
            <Link
              id="tour-profile"
              className="flex items-center gap-12 whitespace-nowrap"
              to={urls.profile}
            >
              <Icon iconName="profile" /> <FormattedMessage id="User profile" />
            </Link>

            <LoginLogout />
          </div>
        </>
      )}
      <div className="mt-auto flex flex-col items-center text-center text-12 font-semibold text-purple">
        <div className="mb-12 text-20 font-bold">
          <FormattedMessage id="contacts.titleCompact" />:
        </div>
        <ContactsBlock />
      </div>
    </div>
  )
}

function LoginLogout() {
  const authStore = useAppModule().authStore
  const navigate = useNavigate()
  const state = useSnapshot(authStore.state)
  const logout = state.isLogin && !state.isAnon

  return (
    <button
      data-testid="logout-btn"
      className="flex items-center gap-12 whitespace-nowrap"
      onClick={() => {
        if (logout) {
          authStore.logoutAndRefresh()
        } else {
          navigate(urls.login)
        }
      }}
    >
      <Icon iconName="exit" />
      <FormattedMessage id={logout ? 'Log out' : 'Log in'} />
    </button>
  )
}
