import { HttpClient } from './httpClient.ts'

export class EdenaiApi {
  constructor(private httpClient: HttpClient) {}
  async speechToText(chatId: number, blob: Blob) {
    const form = new FormData()
    form.append('file', blob)
    return this.httpClient.request<{ text: string }>(
      `/api/speech_to_text/${chatId}/`,
      {
        body: form,
        method: 'POST',
      },
    )
  }

  async textToSpeech(chatId: number, text: string) {
    const response = await this.httpClient.post<{ stream_key: string }>(
      `/api/text_to_speech/${chatId}/`,
      { text },
    )
    return this.httpClient.getFullUrl(
      `/api/text_to_speech/download/${response.stream_key}/`,
    )
  }
}
