import React, { useState } from 'react'
import { cn, uniqueBy } from '../../shared/lib/utils.ts'
import { Icon } from '../../shared/ui/icon/icon.tsx'
import { VideoPlayer } from './videoPlayer.tsx'
import { VideoAvatarBlock } from './OnboardingView.tsx'
import { preloadVideo } from '../../shared/lib/htmlUtils.ts'
import { TooltipContent } from '../../shared/ui/tooltip/tooltip.tsx'
import { FormattedMessage, useIntl } from 'react-intl'

const videos: Record<string, string | undefined> = {
  '/videos/onboarding-0.mp4': 'https://img.edman.ai/videos/onboarding-0.mp4',
  '/videos/onboarding-1.mp4': 'https://img.edman.ai/videos/onboarding-1.mp4',
  '/videos/onboarding-2.mp4': 'https://img.edman.ai/videos/onboarding-2.mp4',
  '/videos/onboarding-3.mp4': 'https://img.edman.ai/videos/onboarding-3.mp4',
  '/videos/onboarding-4.mp4': 'https://img.edman.ai/videos/onboarding-4.mp4',
  '/videos/onboarding-5.mp4':
    'https://img.edman.ai/videos/onboarding-5-nobg.mp4',
  '/videos/tutorial-0.mp4': 'https://img.edman.ai/videos/tutorial-0.mp4',
  '/videos/tutorial-1.mp4': 'https://img.edman.ai/videos/tutorial-1.mp4',
  '/videos/tutorial-2.mp4': 'https://img.edman.ai/videos/tutorial-2.mp4',
  '/videos/tutorial-3.mp4': 'https://img.edman.ai/videos/tutorial-3.mp4',
  '/videos/tutorial-4.mp4': 'https://img.edman.ai/videos/tutorial-4.mp4',
  '/videos/screen-0.mp4': 'https://img.edman.ai/videos/screen-0.mp4',
  '/videos/screen-1.mp4': 'https://img.edman.ai/videos/screen-1.mp4',
  '/videos/screen-2.mp4': 'https://img.edman.ai/videos/screen-2.mp4',
  '/videos/screen-3.mp4': 'https://img.edman.ai/videos/screen-3.mp4',
}

export function getVideoUrl(index: number, tutorial?: 'screen' | 'edman') {
  const url =
    tutorial == 'edman'
      ? `/videos/tutorial-${index}.mp4`
      : tutorial == 'screen'
      ? `/videos/screen-${index}.mp4`
      : `/videos/onboarding-${index}.mp4`

  return videos[url] ?? videos['/videos/onboarding-1.mp4'] ?? ''
}

export async function preloadAllVideos(onlyTutorial = false) {
  try {
    for (const url of uniqueBy(Object.values(videos), (x) => x ?? '').filter(
      (x) => !onlyTutorial || !x?.includes('onboarding'),
    )) {
      await preloadVideo(url ?? '')
    }
  } catch {}
}

export interface ProgressBarProps {
  value: number
  light?: boolean
  slow?: boolean
  wide?: boolean
  className?: string
}

export const ProgressBar = (props: ProgressBarProps) => {
  return (
    <div
      className={cn(
        'w-full max-w-400 rounded-full',
        props.wide ? 'h-[6px]' : 'h-2',
        props.light ? 'bg-purple-light2' : 'bg-violetd-light',
        props.className,
      )}
    >
      <div
        className={cn(
          'h-full rounded-full',
          props.slow ? 'duration-300' : 'duration-100',
          props.value > 0 ? 'transition-width ease-linear' : 'w-0',
          'bg-main',
        )}
        style={{ width: props.value * 100 + '%' }}
      ></div>
    </div>
  )
}

export function Tutorials(props: { showTooltips: boolean }) {
  const [videoIndex, setVideoIndex] = useState(0)
  const [progress, setProgress] = useState(0)
  const videos = [
    [
      0,
      'Vocabulary lesson',
      {
        title: 'Topic words',
        text: 'Topic words.desc',
      },
    ],
    [
      1,
      'Image discussion',
      {
        title: 'Generated image',
        text: 'Generated image.desc',
      },
    ],
    [
      3,
      'Grammar practice',
      {
        title: 'Choose an answer',
        text: 'Choose an answer.desc',
      },
    ],
  ] as const
  const menu = (
    <div className="flex cursor-pointer flex-wrap gap-24 text-16 font-bold text-white sm:flex-col">
      {videos.map(([, title], i) => (
        <div
          className={cn('flex', videoIndex == i && 'text-main-light')}
          key={i}
        >
          <div>
            <div
              onClick={() => {
                setVideoIndex(i)
              }}
            >
              <FormattedMessage id={title} />
            </div>
            <ProgressBar
              key={i}
              light
              className={cn('mt-2', videoIndex != i && 'invisible')}
              value={progress}
            />
          </div>
          <Icon
            iconName="arrowRight"
            className={cn(videoIndex != i && 'invisible')}
          />
        </div>
      ))}
    </div>
  )
  const tooltip = props.showTooltips ? videos[videoIndex][2] : undefined
  const videoId = videos[videoIndex][0]
  const intl = useIntl()
  return (
    <div
      className={cn(
        'flex flex-col items-center sm:flex-row',
        props.showTooltips && 'gap-48',
      )}
    >
      {menu}
      <div>
        <VideoAvatarBlock
          text={intl.formatMessage({ id: 'onboarding.study' })}
          videoUrl={getVideoUrl(videoId, 'edman')}
        />
        <VideoPlayer
          onProgress={setProgress}
          big={true}
          url={getVideoUrl(videoId, 'screen')}
          tooltip={
            tooltip && (
              <TooltipContent
                title={<FormattedMessage id={tooltip.title} />}
                text={<FormattedMessage id={tooltip.text} />}
              />
            )
          }
        />
      </div>
    </div>
  )
}
