import { useSnapshot } from 'valtio/react'
import { useAppModule } from '../appContext.ts'
import { Card } from '../../shared/ui/card/card.tsx'
import { LinkButton } from '../../shared/ui/button/button.tsx'
import { urls } from '../../shared/urls.ts'
import { FormattedMessage } from 'react-intl'
import React from 'react'
import { cn } from '../../shared/lib/utils.ts'

export function BuyTrial(props: { vertical?: boolean }) {
  const authState = useSnapshot(useAppModule().authStore.state)
  if (!authState.isAnon) {
    return null
  }
  return (
    <Card
      bg={props.vertical ? 'white' : 'gray'}
      className={cn(
        'mb-24 flex gap-16 text-20 font-semibold leading-tight',
        props.vertical
          ? 'flex-col items-center'
          : 'items-center justify-between',
      )}
    >
      Получите бесплатный доступ на 7 дней, а вместе с этим - сохранение вашего
      прогресса обучения и персональную программу
      <LinkButton
        bg="violet"
        className="whitespace-nowrap"
        rounded="full"
        to={urls.subscriptions}
      >
        <FormattedMessage id="Get subscription" />
      </LinkButton>
    </Card>
  )
}
