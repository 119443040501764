import React from 'react'
import { Button } from '../button/button.tsx'
import { useNavigate } from 'react-router-dom'

interface InformationBlockProps {
  imageUrl: string
  children?: React.ReactNode
  title: React.ReactNode
  buttonText: React.ReactNode
  buttonId?: string
  returnUrl: string
}

export const InformationBlock = (props: InformationBlockProps) => {
  const navigate = useNavigate()
  return (
    <div className="flex w-full flex-1 items-stretch justify-center bg-purple p-8 sm:items-center">
      <div className="flex w-full max-w-800 flex-col items-center justify-between rounded p-8 sm:p-24">
        <div className="rounded bg-white sm:bg-transparent">
          <img src={props.imageUrl} alt="" />
        </div>
        <div className="whitespace-nowrap text-center text-36 font-bold">
          {props.title}
        </div>
        <div className="">
          {props.children ?? (
            <div className="my-16 max-w-400 text-center text-24 font-semibold">
              Please try again later. If problem consist, please contact support
              team to resolve these problem.
            </div>
          )}
        </div>
        <Button
          bg="violet"
          size="medium"
          id={props.buttonId}
          rounded="full"
          className="w-200"
          onClick={() => {
            navigate(props.returnUrl)
          }}
        >
          {props.buttonText}
        </Button>
      </div>
    </div>
  )
}
