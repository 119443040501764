import { useAppModule } from '../appContext.ts'
import { useNavigate } from 'react-router-dom'
import { getFormObject } from '../../shared/lib/utils.ts'
import { Toasts } from '../toasts/toasts.tsx'
import { useSnapshot } from 'valtio/react'
import { urls } from '../../shared/urls.ts'
import { TextInput } from '../../shared/ui/textInput/textInput.tsx'

export function ConfirmRegistration() {
  return (
    <div
      data-testid="login-page"
      className="mx-auto min-h-screen max-w-screen-sm pt-60"
    >
      <div className="flex justify-center">
        <ConfirmNumber />
      </div>
      <Toasts />
    </div>
  )
}

function ConfirmNumber() {
  const authStore = useAppModule().authStore
  const state = useSnapshot(authStore.state)
  const navigate = useNavigate()
  return (
    <form
      className="w-full max-w-sm"
      onSubmit={(e) => {
        e.preventDefault()
        const data = getFormObject(e.target as HTMLFormElement)
        void authStore.confirmPhone(data.code).then((valid) => {
          if (valid) {
            navigate(urls.onboarding())
          }
        })
      }}
    >
      <div className="w-full">
        <label>
          <span>Code was sent to {state.user?.login}</span>
        </label>
        <TextInput
          type="text"
          name="code"
          data-testid="code"
          placeholder="12345"
          required={true}
          className="w-full"
        />
        {state.confirmError && 'Code is not valid'}
      </div>

      <div className="mt-20 flex w-full gap-12">
        <button data-testid="confirm-btn" type="submit" className="btn">
          Confirm
        </button>
        <button
          data-testid="confirm-btn"
          onClick={(e) => {
            e.preventDefault()
            void authStore.resendCode()
          }}
          className="btn"
        >
          Resend code
        </button>
      </div>
    </form>
  )
}
