import { AppHeader } from '../features/appHeader/appHeader.tsx'
import { ContactsView } from '../features/contacts/contactsView.tsx'

export function ContactsPage() {
  return (
    <div className="flex w-full flex-col">
      <AppHeader />
      <ContactsView />
    </div>
  )
}
