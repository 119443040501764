import { useSnapshot } from 'valtio/react'
import { CorrectImage, WrongImage } from '../../shared/ui/images/images.tsx'
import { Button } from '../../shared/ui/button/button.tsx'
import { FormattedMessage } from 'react-intl'
import React from 'react'
import { none, taggedUnion, type } from '../../shared/lib/taggedUnion.ts'
import { classed } from '@tw-classed/react'
import { useIsMobile } from '../../shared/lib/hooks.ts'
import { createPortal } from 'react-dom'
import { proxy } from 'valtio'
import { emptyFn } from '../../shared/lib/utils.ts'
import { useAppModule } from '../appContext.ts'

export const AnswerStatus = taggedUnion({
  correct: none,
  complete: none,
  wrong: type<{ description: string }>(),
})
export type AnswerStatus = typeof AnswerStatus.type

interface State {
  answerStatus?: AnswerStatus
  onNextQuestion: () => void
}

export class AnswerStatusStore {
  state = proxy<State>({
    onNextQuestion: emptyFn,
  })

  setStatus(status: AnswerStatus, onNextQuestion: () => void) {
    this.state.answerStatus = status
    this.state.onNextQuestion = onNextQuestion
  }
  nextQuestion() {
    this.state.answerStatus = undefined
    this.state.onNextQuestion()
  }
}

const Title = classed.div('ml-16 flex items-center gap-8 text-22 font-bold', {
  variants: {
    isCorrect: {
      true: 'mb-24 text-green-dark ',
      false: 'mb-8 text-alert',
    },
  },
})

const Container = classed.div(
  'pointer-events-none inset-x-0 bottom-0 z-10 overflow-hidden',
  {
    variants: {
      isMobile: {
        true: 'fixed',
        false: 'absolute',
      },
    },
  },
)

const InnerContainer = classed.div(
  'flex flex-col rounded-t-32 px-16 pb-40 pt-32 transition-transform ease-out-circ',
  {
    variants: {
      isCorrect: {
        true: 'bg-green-light',
        false: 'bg-alert-light3',
      },
      show: {
        true: 'pointer-events-auto translate-y-0',
        false: 'translate-y-full',
      },
    },
  },
)

export const AnswerStatusDrawer = () => {
  const store = useAppModule().answerStatusStore
  const state = useSnapshot(store.state)
  const show =
    state.answerStatus !== undefined && state.answerStatus.tag !== 'complete'
  const isMobile = useIsMobile()
  const isCorrect = state.answerStatus?.tag === 'correct'
  const content = (
    <Container isMobile={isMobile}>
      <InnerContainer isCorrect={isCorrect} show={show}>
        <Title isCorrect={isCorrect}>
          {isCorrect ? (
            <>
              <CorrectImage /> Amazing!
            </>
          ) : (
            <>
              <WrongImage /> Oops, that’s not correct!
            </>
          )}
        </Title>
        <div className="mb-16 ml-16 text-16 font-semibold text-alert">
          {state.answerStatus?.tag == 'wrong' &&
            state.answerStatus.data.description}
        </div>
        <Button
          onClick={() => {
            store.nextQuestion()
          }}
          size="extralarge"
          rounded="full"
          bg={isCorrect ? 'green-gradient' : 'red-gradient'}
        >
          <FormattedMessage id="Continue" />
        </Button>
      </InnerContainer>
    </Container>
  )
  const container = document.body

  return isMobile ? createPortal(content, container) : content
}
