import { InformationBlock } from '../shared/ui/informationBlock/informationBlock.tsx'

export const NotFoundPage = () => {
  return (
    <div className="flex min-h-dvh w-full flex-col">
      <InformationBlock
        imageUrl="/images/404.png"
        title="Page not found"
        buttonText="Back to Home page"
        returnUrl="/"
      />
    </div>
  )
}
