import { proxy } from 'valtio'
import {
  deleteKey,
  emptyFn,
  getValueByKey,
  shuffleArray,
  unzip,
} from '../../../shared/lib/utils.ts'
import { SpeechService } from '../../speech/speechService.ts'

export type TextOrSound = string | { sound_url: string }
function getMatchWordText(word: TextOrSound) {
  return typeof word === 'string' ? word : word.sound_url
}
export interface WordWithMeaning {
  word: TextOrSound
  synonym: string
}

type UserAnswers = Record<number, { answer: number; isCorrect: boolean }>

interface State {
  matches: readonly WordWithMeaning[]
  words: readonly TextOrSound[]
  meanings: readonly string[]
  answers: readonly number[]
  userAnswers: UserAnswers
  selectedIndex?: number
  buttonStatuses: [boolean | 'none' | 'selected', boolean | 'none'][]
}

const checkIfCorrect = (
  userAnswers: UserAnswers,
  index: number,
  answer: number,
  isAnswer: boolean,
) => {
  const userAnswer = Object.entries(userAnswers).find((x) =>
    isAnswer ? x[1].answer == index : x[0] == index.toString(),
  )
  if (!userAnswer) {
    return 'none' as const
  }
  return userAnswer[1].isCorrect
}

export class MatchWordsStore {
  state: State

  getResult() {
    const prefix =
      'I have completed the "Match words with synonyms" exercise.\n'
    return (
      prefix +
      this.state.words
        .map(
          (x, i) =>
            `${getMatchWordText(x)} - ${
              this.state.answers[this.state.userAnswers[i].answer] ?? ''
            }`,
        )
        .join(', ')
    )
  }

  constructor(
    matches: readonly WordWithMeaning[],
    private speechService: SpeechService,
    private onComplete: (result: string) => void,
  ) {
    const [words, meaningsOrdered] = unzip(
      matches.map((x) => [x.word, x.synonym]),
    )
    const meanings = shuffleArray(meaningsOrdered)
    const answers = meaningsOrdered.map((x) => meanings.indexOf(x))
    this.state = proxy<State>({
      words,
      meanings,
      answers,
      matches,
      userAnswers: [],
      buttonStatuses: answers.map(() => ['none', 'none']),
    })
  }

  getButtonStatuses() {
    const x: number[] = [...this.state.answers]
    const statuses: [boolean | 'none' | 'selected', boolean | 'none'][] = x.map(
      (answer, i) => [
        checkIfCorrect(this.state.userAnswers, i, answer, false),
        checkIfCorrect(this.state.userAnswers, i, answer, true),
      ],
    )
    if (this.state.selectedIndex !== undefined) {
      statuses[this.state.selectedIndex][0] = 'selected'
    }
    return statuses
  }

  select(index: number, isAnswer: boolean) {
    const userAnswer = getValueByKey(this.state.userAnswers, index)
    if (!isAnswer && !userAnswer?.isCorrect) {
      this.state.selectedIndex = index
      deleteKey(this.state.userAnswers, index)
      const word = this.state.words[index]
      if (typeof word !== 'string') {
        void this.speechService.play(1, word.sound_url, emptyFn)
      }
    } else if (this.state.selectedIndex !== undefined) {
      const isCorrect = this.state.answers[this.state.selectedIndex] == index
      this.state.userAnswers[this.state.selectedIndex] = {
        answer: index,
        isCorrect,
      }
      const selectedIndex = this.state.selectedIndex
      this.state.selectedIndex = undefined
      setTimeout(() => {
        if (!isCorrect) {
          deleteKey(this.state.userAnswers, selectedIndex)

          this.updateButtonStatuses()
        }
      }, 500)
    }
    this.updateButtonStatuses()
  }

  updateButtonStatuses() {
    this.state.buttonStatuses = this.getButtonStatuses()
    if (
      this.state.buttonStatuses.every((x) => x[0] === true && x[1] === true)
    ) {
      this.onComplete('I have completed the exercise')
    }
  }
}
