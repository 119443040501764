import React from 'react'
import { useSnapshot } from 'valtio'
import { FormattedMessage } from 'react-intl'
import { RateLessonStore } from './rateLessonStore.ts'
import { useLazyRef } from '../../../shared/lib/hooks.ts'
import { cn, nilMap } from '../../../shared/lib/utils.ts'
import { TextArea } from '../../../shared/ui/textInput/textInput.tsx'
import { Button } from '../../../shared/ui/button/button.tsx'

function FormBlock(props: {
  title: React.ReactNode
  children: React.ReactNode
}) {
  return (
    <div>
      <div className="mb-4 text-center text-14">{props.title}</div>
      {props.children}
    </div>
  )
}

export const RateLesson = (props: {
  className?: string
  onSubmit: (data: {
    suitableLevel?: number
    experienceRating?: number
    additionalFeedback: string
  }) => void
}) => {
  const store = useLazyRef(() => new RateLessonStore()).current
  const state = useSnapshot(store.state)

  return (
    <div className={cn('flex flex-col gap-16 sm:max-w-300', props.className)}>
      <FormBlock title={<FormattedMessage id="ratelesson.suitableLevel" />}>
        <div className="flex justify-center gap-8">
          {['👎', '🤏', '👍'].map((emoji, index) => (
            <button
              key={index}
              className={cn(state.suitableLevel !== index && 'opacity-40')}
              onClick={() => {
                store.setSuitableLevel(index)
              }}
            >
              {emoji}
            </button>
          ))}
        </div>
      </FormBlock>

      <FormBlock title={<FormattedMessage id="ratelesson.experienceRating" />}>
        <div className="flex  justify-center gap-8">
          {['😞', '😕', '😐', '😊', '😍'].map((emoji, index) => (
            <button
              key={index}
              className={cn(state.experienceRating !== index && 'opacity-50')}
              onClick={() => {
                store.setExperienceRating(index)
              }}
            >
              {emoji}
            </button>
          ))}
        </div>
      </FormBlock>

      <FormBlock
        title={<FormattedMessage id="ratelesson.additionalFeedback" />}
      >
        <TextArea
          className="w-full border-2 border-purple-light"
          value={state.additionalFeedback}
          onChange={(e) => {
            store.setAdditionalFeedback(e.target.value)
          }}
        />
      </FormBlock>

      <Button
        bg="main"
        className="mx-auto max-w-fit"
        rounded="full"
        disabled={state.disableButton}
        onClick={() => {
          props.onSubmit({
            suitableLevel: nilMap(state.suitableLevel, (x) => x + 1),
            experienceRating: nilMap(state.experienceRating, (x) => x + 1),
            additionalFeedback: state.additionalFeedback,
          })
        }}
      >
        <FormattedMessage id="ratelesson.sendFeedback" />
      </Button>
    </div>
  )
}
