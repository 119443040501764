import { Button } from '../../shared/ui/button/button.tsx'
import React, { ComponentProps, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSnapshot } from 'valtio/react'
import { useStores } from '../chats/chatView.tsx'
import { cn } from '../../shared/lib/utils.ts'
import { classed } from '@tw-classed/react'

export const ExerciseContainer = classed.div(
  'relative -mx-16 overflow-hidden  px-16',
)

export function ExerciseTitle(props: { title: string; className?: string }) {
  return (
    <div className={cn('text-center text-24 font-extrabold', props.className)}>
      {props.title}
    </div>
  )
}

export function ExerciseHeader(props: {
  title: React.ReactNode
  subtitle?: React.ReactNode
}) {
  return (
    <div className="mb-16">
      <div className="text-24 font-bold leading-[1.2em]">{props.title}</div>
      {props.subtitle && (
        <div className="hidden text-14 font-medium text-light sm:block">
          {props.subtitle}
        </div>
      )}
    </div>
  )
}

export const ExerciseButton = classed.button(
  'w-full rounded-10 border-4 border-white p-12 text-center text-16 font-semibold',
  {
    variants: {
      isCorrect: {
        true: 'bg-green-gradient text-green-dark shadow-button-green',
        false: 'bg-red-gradient text-alert shadow-button-red',
        selected: 'bg-user-message text-main-light',
        none: 'bg-white',
      },
    },
  },
)

export function CompleteButton(props: {
  completed: boolean
  onComplete: () => void
  bg?: ComponentProps<typeof Button>['bg']
}) {
  const store = useStores().chatStore
  const state = useSnapshot(store.state, { sync: true })

  const [complete, setComplete] = useState(false)
  return (
    !complete &&
    !props.completed && (
      <Button
        bg={props.bg ?? 'violet'}
        size="medium"
        rounded="full"
        id="send_to_edman"
        className="mt-16 cursor-pointer whitespace-nowrap"
        disabled={state.disabledByLoading}
        onClick={() => {
          setComplete(true)
          props.onComplete()
        }}
      >
        <FormattedMessage id="exercise.send" />
      </Button>
    )
  )
}
