import { useRouteError } from 'react-router-dom'
import { InformationBlock } from '../shared/ui/informationBlock/informationBlock.tsx'
import { captureException } from '@sentry/react'
import { useEffect } from 'react'

export function ErrorPage() {
  const error = useRouteError() as Error
  console.error(error)
  useEffect(() => {
    captureException(error, { level: 'fatal' })
  }, [error])

  return (
    <InformationBlock
      imageUrl="/images/status-error.png"
      title="Error"
      buttonText="Back to Home page"
      returnUrl="/"
    >
      <span className="p-16">Error! {error.message}</span>
    </InformationBlock>
  )
}
