import { classed } from '@tw-classed/react'
import { useAppModule } from '../appContext.ts'
import { useSnapshot } from 'valtio/react'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { urls } from '../../shared/urls.ts'
import { MobileMenuBotton } from '../rightmenu/menu.tsx'
import { Icon } from '../../shared/ui/icon/icon.tsx'
import { SwitchLanguage } from '../../shared/ui/switchLanguage/SwitchLanguage.tsx'
import { cn } from '../../shared/lib/utils.ts'

export function AppHeaderNavigationButton(props: {
  backButton?: boolean
  white?: boolean
}) {
  const navigate = useNavigate()
  return (
    <button
      onClick={() => {
        if (props.backButton) {
          navigate(urls.program)
        }
      }}
      className={cn('w-24 sm:hidden', props.white && 'mxs:text-black')}
    >
      {props.backButton ? <Icon iconName="left" /> : <MobileMenuBotton />}
    </button>
  )
}

export const AppHeaderContainer = classed.div(
  'relative flex h-104 shrink-0 items-center gap-16 bg-violetd px-16 text-28 font-semibold text-white sm:px-36',
)

export function AppHeader(props: {
  backButton?: boolean
  children?: React.ReactNode
  className?: string
}) {
  return (
    <AppHeaderContainer className={props.className}>
      <AppHeaderNavigationButton backButton={props.backButton} />
      {props.children}
      <LangSwitcher />
      <div className="absolute -bottom-8 left-0 hidden size-8 sm:grid">
        <div className="col-start-1 row-start-1 bg-violetd"></div>
        <div className="col-start-1 row-start-1 rounded-tl-8 bg-light"></div>
      </div>
    </AppHeaderContainer>
  )
}

export function LangSwitcher(props: {
  top?: boolean
  darkBorder?: boolean
  className?: string
}) {
  const appStore = useAppModule().appStore
  const state = useSnapshot(appStore.state)
  return (
    <div
      className={cn(
        'flex items-center rounded-8 sm:absolute sm:right-12 sm:top-12',
        props.top ? 'sm:right-0 sm:top-0' : 'sm:inset-y-0 sm:right-24',
        props.className,
      )}
    >
      <SwitchLanguage
        darkBorder={props.darkBorder}
        language={state.language}
        onChange={(lang) => {
          appStore.setLanguage(lang)
        }}
      />
    </div>
  )
}
