export function preloadVideo(video: string) {
  return new Promise((resolve, reject) => {
    const videoElement = document.createElement('video')
    videoElement.src = video
    videoElement.muted = true
    videoElement.preload = 'auto'
    videoElement.addEventListener(
      'canplaythrough',
      () => {
        resolve(undefined)
      },
      { once: true },
    )
    videoElement.addEventListener(
      'error',
      () => {
        reject(undefined)
      },
      { once: true },
    )
    videoElement.load()
  })
}

export function preloadImage(url: string) {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.src = url
    img.onload = () => {
      resolve(undefined)
    }
    img.onerror = () => {
      reject(undefined)
    }
  })
}
