import React, { ReactNode } from 'react'
import { escapeRegExp } from '../../shared/lib/utils.ts'
import { WithTooltip } from '../../shared/ui/tooltip/tooltip.tsx'

interface TextWithTooltipsProps {
  description: string
  tooltips: { text: string; tooltip: ReactNode }[]
  className?: string
}

export const TextWithTooltips = (props: TextWithTooltipsProps) => {
  if (!props.tooltips.length)
    return (
      <div className={props.className}>
        <span>{props.description}</span>
      </div>
    )
  const getTextTooltips = () => {
    const texts = Object.fromEntries(
      props.tooltips.map((x) => [x.text, x.tooltip]),
    )
    const regex = new RegExp(
      `(${Object.keys(texts)
        .map((x) => escapeRegExp(x))
        .join('|')})`,
      'gi',
    )
    const parts = props.description.split(regex)

    return parts.map((part, index) =>
      texts[part] ? (
        <WithTooltip key={index} tooltip={texts[part]}>
          <span className="cursor-pointer underline decoration-alert decoration-wavy">
            {part}
          </span>
        </WithTooltip>
      ) : (
        part
      ),
    )
  }

  return <div className={props.className}>{getTextTooltips()}</div>
}
