import {
  OnboardingContext,
  OnboardingView,
} from '../features/onboarding/OnboardingView.tsx'
import { useLazyRef, useSubscribe } from '../shared/lib/hooks.ts'
import { OnboardingStore } from '../features/onboarding/OnboardingStore.ts'
import { useAppModule } from '../features/appContext.ts'
import { useMount } from '../shared/lib/utils.ts'
import { useSnapshot } from 'valtio/react'
import { PropsWithChildren, useEffect, useLayoutEffect, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { urls } from '../shared/urls.ts'
import { messages } from '../shared/translations/messages.ts'
import { IntlProvider } from 'react-intl'
import { useOnboardingData } from '../features/onboarding/onboardingData.ts'
import { OnboardingStep } from '../shared/data/onboardingSteps.ts'

export function OnboardingPage() {
  return (
    <IntlProvider locale="ru" messages={messages.ru}>
      <OnboardingPageInner />
    </IntlProvider>
  )
}

export function OnboardingProvider({ children }: PropsWithChildren) {
  const appModule = useAppModule()
  const authStore = useAppModule().authStore

  useMount(() => {
    appModule.authStore.trackMetrika('demo_lesson_start')
    appModule.authStore.trackEvent('edman_intro_start')
    void authStore.saveMarkData(true)
  })
  const data = useOnboardingData()

  const store = useLazyRef(
    () =>
      new OnboardingStore(
        appModule.chatsApi,
        appModule.authStore,
        appModule.subscriptionService(),
        data,
        appModule.appSessionStorage,
      ),
  ).current
  useMount(() => {
    void store.load()
  })
  const state = useSnapshot(store.state)
  const step = state.stepType
  const navigate = useNavigate()
  const params = useParams<OnboardingPageParams>()
  const routerStep = params.step ?? 'hello'
  const routerPrevStepRef = useRef<OnboardingStep>()

  useSubscribe(store.state, () => {
    store.updateSession()
  })

  useLayoutEffect(() => {
    store.setStep(routerStep)
    store.updateSession()
    routerPrevStepRef.current = routerStep
  }, [routerStep, store])

  useEffect(() => {
    if (!state.completed && step !== routerPrevStepRef.current) {
      navigate(urls.onboarding(step))
    }
  }, [navigate, step, state.completed])

  useMount(() => {
    if (!store.state.completed) {
      navigate(urls.onboarding(step), { replace: true })
    }
  })

  return (
    <OnboardingContext.Provider value={store}>
      {children}
    </OnboardingContext.Provider>
  )
}

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
type OnboardingPageParams = {
  step: OnboardingStep
}

function OnboardingPageInner() {
  return (
    <OnboardingProvider>
      <OnboardingView />
    </OnboardingProvider>
  )
}
