import { createBrowserRouter, Navigate, RouteObject } from 'react-router-dom'
import { ErrorPage } from '../pages/errorPage.tsx'
import { Login } from '../features/auth/login.tsx'
import { Layout } from './layout.tsx'
import { isWebDomain, urls } from '../shared/urls.ts'
import { ChatPage } from '../pages/chatPage.tsx'
import { ProfilePage } from '../pages/profilePage.tsx'
import { ConfirmRegistration } from '../features/auth/confirmRegistration.tsx'
import { OnboardingPage, OnboardingProvider } from '../pages/onboardingPage.tsx'
import { ProgramPage } from '../pages/programPage.tsx'
import { NotFoundPage } from '../pages/notFoundPage.tsx'
import { PaymentStatusPage } from '../pages/paymentStatusPage.tsx'
import { SubscriptionsPage } from '../pages/subscriptionsPage.tsx'
import { DemoPage } from '../pages/demoPage.tsx'
import { ContactsPage } from '../pages/contactsPage.tsx'
import { appVersion } from '../shared/lib/utils.ts'
import {
  AuthAccess,
  ConfirmAccess,
  DemoAccess,
  LoginAccess,
  SubscriptionsAccess,
} from './guards.tsx'
import { ProgramPage2 } from '../pages/programPage2.tsx'

const chooseYourLevel = isWebDomain
  ? {
      path: urls.chooseyourlevel,
      element: <ProgramPage registration={true} />,
    }
  : {}

export const routeObjects: RouteObject[] = [
  {
    path: '/',
    element: (
      <OnboardingProvider>
        <AuthAccess>
          <Layout withMenu={true} scroll={true} />
        </AuthAccess>
      </OnboardingProvider>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <Navigate to={urls.learningProfile()} />,
      },
      {
        path: urls.learningProfile('*'),
        element: <ProgramPage2 registration={false} />,
      },
    ],
  },
  {
    path: '/',
    element: (
      <AuthAccess>
        <Layout withMenu={true} />
      </AuthAccess>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: urls.lesson(':tag', '*'),
        element: <ChatPage />,
      },
      {
        path: urls.profile,
        element: <ProfilePage />,
      },
      {
        path: '/program',
        element: <Navigate to={urls.program} />,
      },
      {
        path: urls.payment(':status', ':plan?'),
        element: <PaymentStatusPage />,
      },
    ],
  },
  {
    path: '/',
    element: <Layout fullWidth={true} withMenu={false} />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: urls.onboarding(':step?'),
        element: <OnboardingPage />,
      },
    ],
  },
  {
    path: urls.login,
    element: (
      <LoginAccess>
        <Login registration={false} />
      </LoginAccess>
    ),
  },
  {
    path: urls.registration,
    element: (
      <LoginAccess>
        <Login registration={true} />
      </LoginAccess>
    ),
  },
  {
    path: urls.subscriptions,
    element: (
      <OnboardingProvider>
        <SubscriptionsAccess>
          <SubscriptionsPage country="ru" />
        </SubscriptionsAccess>
      </OnboardingProvider>
    ),
  },
  {
    path: urls.subscriptions_kz,
    element: (
      <OnboardingProvider>
        <SubscriptionsAccess>
          <SubscriptionsPage country="kz" />
        </SubscriptionsAccess>
      </OnboardingProvider>
    ),
  },
  {
    element: <Layout white withMenu={true} />,
    path: '/',
    children: [
      {
        path: urls.demo(':infoBlock?'),
        element: (
          <OnboardingProvider>
            <DemoAccess>
              <DemoPage />
            </DemoAccess>
          </OnboardingProvider>
        ),
      },
      {
        path: urls.contacts,
        element: <ContactsPage />,
      },
      chooseYourLevel,
    ],
  },
  {
    path: urls.confirm,
    element: (
      <ConfirmAccess>
        <ConfirmRegistration />
      </ConfirmAccess>
    ),
  },
  {
    path: '/version',
    element: appVersion,
  },
  {
    path: '*',
    element: <NotFoundPage />,
  },
]

export const router = createBrowserRouter(routeObjects)
