function requestAnimationFrameInterval(callback: () => void) {
  let isRunning = true

  function loop() {
    if (!isRunning) return
    callback()
    requestAnimationFrame(loop)
  }

  loop()

  return () => {
    isRunning = false
  }
}
export function howlerPlay(
  sound: Howl,
  progressCallback: (progress: number, duration: number) => void,
) {
  function startHowlInterval() {
    progressCallback(0, sound.duration())
    const stopInterval = requestAnimationFrameInterval(() => {
      if (sound.playing()) {
        progressCallback(sound.seek() / sound.duration(), sound.duration())
      }
    })
    return () => {
      progressCallback(1, sound.duration())
      stopInterval()
    }
  }

  return new Promise<void>((resolve, reject) => {
    const cleanUp = startHowlInterval()
    sound.once('loaderror', (_, error) => {
      cleanUp()
      reject(new Error(String(error)))
    })
    sound.once('playerror', (_, error) => {
      cleanUp()
      reject(new Error(String(error)))
    })
    sound.once('end', () => {
      cleanUp()
      resolve()
    })
    sound.once('stop', () => {
      cleanUp()
      resolve()
    })
    sound.play()
  })
}
